import React, {Component} from 'react';
import CountUp from 'react-countup';
import './countup.scss';
class CounterBlock extends Component {
    render (){
        return (
            <div className={`countup-wrap`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={5500} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>SQUARE Footage</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={2500} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>Parking Spots</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={1890} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>Apartments</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <CountUp start={0} end={2200} delay={0}>
                                {({ countUpRef }) => (
                                    <div className="countup-box">
                                        <div className="counter-number">
                                            <span ref={countUpRef} />
                                        </div>

                                        <div className="counter-title">
                                            <span>ROOMS</span>
                                        </div>
                                    </div>
                                )}
                            </CountUp>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CounterBlock
